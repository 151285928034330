<script setup>
const props = defineProps({
  customClass: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: () => false
  },
  color: {
    type: String,
    default: 'gray'
  },
  withIcon: {
    type: Boolean,
    default: false
  },
  href: [String, Object],
  target: String,
  fit: Boolean,
  small: Boolean,
  external: Boolean
})

const refState = ref();
const setBusy = () => refState.value.setBusy();
const setNone = () => refState.value.setNone();

defineExpose({
  setBusy,
  setNone
})

</script>

<template>
    <NuxtLink v-if="!props.external" :to="href" :target="target"
              :class="[
                'flex relative justify-center items-center rounded border cursor-pointer transition-colors select-none w-full',
                {'h-10 font-light': !small},
                {'h-7 font-extralight text-xs': small},
                {'pointer-events-none opacity-30': props.disabled},
                {'border-gray-700 text-white bg-gray-700 hover:bg-black hover:border-black': props.color === 'gray'},
                {'border-white text-black bg-white hover:bg-gray-100 hover:border-gray-100': props.color === 'white'},
                {'border-azure text-white bg-azure hover:bg-azure-dark hover:border-azure-dark': props.color === 'azure'},
                {'border-celadon text-white bg-celadon hover:bg-grass-green hover:border-grass-green': props.color === 'celadon'},
                {'border-gray-700 text-gray-700 bg-transparent hover:border-black hover:border-2 hover:text-black': props.color === 'transparent'},
                {'!w-fit':fit}
              ]"
    >
    <ButtonStateBlend ref="refState" />
      <div :class="{'flex items-center': props.withIcon}" class="px-4">
        <slot />
      </div>
    </NuxtLink>
    <NuxtLink v-else external :to="href" :target="target"
              :class="[
                customClass ? customClass : 'relative',
                'flex justify-center items-center rounded border cursor-pointer transition-colors select-none w-full',
                {'h-10 font-light': !small},
                {'h-7 font-extralight text-xs': small},
                {'border-gray-700 text-white bg-gray-700 hover:bg-black hover:border-black': props.color === 'gray'},
                {'border-white text-black bg-white hover:bg-gray-100 hover:border-gray-100': props.color === 'white'},
                {'border-azure text-white bg-azure hover:bg-azure-dark hover:border-azure-dark': props.color === 'azure'},
                {'border-celadon text-white bg-celadon hover:bg-grass-green hover:border-grass-green': props.color === 'celadon'},
                {'border-gray-700 text-gray-700 bg-transparent hover:border-black hover:border-2 hover:text-black': props.color === 'transparent'},
                {'!w-fit':fit}
              ]"
    >
      <ButtonStateBlend ref="refState" />
      <div :class="{'flex items-center': props.withIcon}" class="px-4">
        <slot />
      </div>
    </NuxtLink>
</template>
