<script setup>
let currentState = "";

const state = ref("");
const animateState = ref([]);
const visibleState = ref([]);

const animateOut = () => {
  return new Promise(resolve => {
    if (currentState === "") {
      resolve();
      return;
    }
    animateState.value[currentState] = false;
    setTimeout(() => {
      visibleState.value[currentState] = false;
      resolve();
    }, 200);
  })
}

const animateIn = () => {
  return new Promise(resolve => {
    visibleState.value[currentState] = true;
    setTimeout(() => {
      animateState.value[currentState] = true;
      resolve();
    }, 50);
  })
}

const setBusy = () => state.value = 'busy';
const setNone = () => state.value = '';

defineExpose({
  setBusy,
  setNone
})

watch(() => state.value,
  async (newState) => {
    if (currentState === newState) return;
    await animateOut();
    currentState = newState;
    animateIn();
  }
)


</script>


<template>
  <div class="hidden absolute rounded inset-0 bg-white/80 z-20 opacity-0 justify-center items-center transition-opacity"
       :class="{'!flex': visibleState['busy'], 'opacity-100': animateState['busy']}">
    <EffectSpinLoader class="!w-5 !h-5"/>
  </div>
</template>
